import React from 'react';
import styled from 'styled-components';
import { Layout } from '../layout';
import { device } from '../utils/devices';
import Seo from '../utils/seo';
import {
  TypewriterText,
  LogoSection,
  EndSection,
  AnimationFadeWrapper,
} from '../components';
import { WorkMinBedrift, WorkMulticonsult } from '../work';

const PageHeader = styled.div`
  position: sticky;
  inset: 56px auto auto;
  scroll-margin-top: 56px;
  z-index: 1;

  height: calc(100vh - 56px - 2.5rem);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const PageHeaderTitle = styled.h2`
  font-size: 2.5rem;
  line-height: 3.75rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-block-start: 24px;
  @media ${device.tablet} {
    font-size: 3rem;
    line-height: 4.5rem;
    padding-block-start: 32px;
  }
  @media ${device.laptop} {
    font-size: 3.5rem;
    line-height: 5.25rem;
    padding-block-start: 40px;
  }
`;

const PageHeaderSubTitle = styled.h1`
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 400;
  letter-spacing: -0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-block-end: 48px;
  @media ${device.tablet} {
    font-size: 1.5rem;
  }
  @media ${device.laptop} {
    font-size: 1.5rem;
  }
`;
const PageContent = styled.article`
  position: relative;
  background-color: var(--color-background);
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IndexPageProps {
  data?: any;
}

const IndexPage: React.FC<IndexPageProps> = () => {
  return (
    <>
      <Layout withHeader withFooter>
        <AnimationFadeWrapper duration={0.4}>
          <PageHeader>
            <PageHeaderTitle>
              Senior{' '}
              <TypewriterText
                firstWords={['']}
                shuffledWords={['UX', 'service', 'product']}
                lastWords={[':)', ' ']}
              />
              designer
            </PageHeaderTitle>
            <PageHeaderSubTitle>
              <TypewriterText firstWords={['Recent work']} />
              <br />
              <TypewriterText firstWords={['2020-22']} />
            </PageHeaderSubTitle>
          </PageHeader>
          <PageContent>
            <WorkMinBedrift />
            <WorkMulticonsult />
            <LogoSection />
            <EndSection />
          </PageContent>
        </AnimationFadeWrapper>
      </Layout>
    </>
  );
};

export default IndexPage;

export const Head = () => <Seo />;
