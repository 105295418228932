import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import {
  Text,
  WorkSectionWrapper,
  WorkSectionDescription,
  WorkSectionContentWrapper,
  ContentImageCentered,
  ContentImageFull,
  ContentTextSection,
} from '../components';

interface WorkMulticonsultProps {}

export const WorkMulticonsult: React.FC<WorkMulticonsultProps> = () => (
  <StaticQuery
    query={graphql`
      query {
        iphoneImage: file(
          relativePath: { eq: "images/Multiconsult/Multiconsult iPhone.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 40 })
          }
        }
        mockupImageBrowsers: file(
          relativePath: { eq: "images/Multiconsult/Multiconsult browsers.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 40 })
          }
        }
        workshopImage: file(
          relativePath: { eq: "images/Multiconsult/Multiconsult ws1.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 40 })
          }
        }
        componentsImage: file(
          relativePath: {
            eq: "images/Multiconsult/Multiconsult components.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 40 })
          }
        }
        sketchImage: file(
          relativePath: {
            eq: "images/Multiconsult/Multiconsult sketch frames.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 40 })
          }
        }
      }
    `}
    render={(data) => {
      const PhoneImage = data ? getImage(data.iphoneImage) : null;
      const PhoneImageAlt =
        'An image of three iPhone 13s with a screen showing the UI for the green energy calculator. White background, with checkboxes and a progress bar displaying the calculations visually.';

      const BrowserImage = data ? getImage(data.mockupImageBrowsers) : null;
      const BrowserImageAlt =
        'An image of two browser windows, showing the UI for the green energy calculator. White background, with checkboxes and a progress bar displaying the calculations visually.';

      const WorkshopImage = data ? getImage(data.workshopImage) : null;
      const WorkshopImageAlt =
        'An image of a miro board workshop, showing a large amount of yellow sticky notes.';
      const ComponentsImage = data ? getImage(data.componentsImage) : null;
      const ComponentsImageAlt =
        'An image showing a Figma window of components used in the prototype creation.';

      const SketchImage = data ? getImage(data.sketchImage) : null;
      const SketchImageAlt =
        'An image a Figma-window with instructions and notes about how the product can work.';

      return (
        <WorkSectionWrapper>
          <WorkSectionDescription
            title="Multiconsult - Energy loans"
            role="Lead Product Designer"
            createdWith={[
              <a
                key="MulticonsultLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.multiconsult.no/"
              >
                Multiconsult
              </a>,
              ' and ',
              <a
                key="BredvidLink2"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bredvid.no"
              >
                Bredvid
              </a>,
            ]}
            work="Workshop facilitation, UX research, Prototyping, Graphic design"
            // link={
            //   <a
            //     target="_blank"
            //     rel="noopener noreferrer"
            //     href="https://www.sparebank1.no/nb/ostlandet/privat/lan/boliglan/gront-boliglan/gront-energilan/energi-kalkulator.html"
            //   >
            //     SpareBank1
            //   </a>
            // }
          >
            Multiconsult are engineering experts.
            <br />
            <br />
            In spring 2022, as energy costs were starting to soar, they wanted
            to use their expertise to help people make meaningful changes to
            their homes instead of surface-tweaks and beautification.
            <br />
            <br />
            One way of doing this was incentivising sustainable renovation
            through green loans with low interest rates, and by spreading
            awareness of energy savings.
            <br />
            <br />
            As part of a two-person team, I designed a product to help people
            evaluate how sustainable their renovations efforts would be, and to
            assist them when they apply for loans.
          </WorkSectionDescription>
          <WorkSectionContentWrapper>
            <ContentImageFull
              image={BrowserImage}
              imageAlt={BrowserImageAlt}
              eager
            ></ContentImageFull>
            <ContentTextSection>
              <Text heading3 alignCenter as="h2" pb="40px">
                Backdrop
              </Text>
              <Text body1 as="p">
                Through research and analysis, Multiconsult knew about the
                drawbacks with current renovation trends.
                <br></br>
                <br></br>
                When people were renovating, they tended to spend more time and
                effort on surface-fixes instead of sustainable change. This was
                largely due to surface-renovations being perceived to be
                cheaper, require less effort, and still having a large impact on
                property-prices.
                <br></br>
                <br></br>
                Multiple banks had already started providing people with
                financial incentives to change this behaviour. They called these
                incentives "Green" energy loans; Loans with lower interest rates
                in return for making meaningful renovation efforts.
                <br></br>
                <br></br>
                Applying for these energy loans wasn't easy, though.
                <br></br>
                <br></br>
                First you had to figure out if your renovation would have an
                impact on energy savings. Then you had to actually provide
                evidence to the bank after the renovations were done. If you
                couldn't, they wouldn't be able to reduce the interest rates.
              </Text>
            </ContentTextSection>
            <ContentImageFull
              image={WorkshopImage}
              imageAlt={WorkshopImageAlt}
              description="Sticky notes from two of our discovery workshops."
              eager
            ></ContentImageFull>
            <ContentTextSection>
              <Text heading3 alignCenter as="h2" pb="40px">
                Opportunity
              </Text>
              <Text body1 as="p">
                As a first step, we set out to help them deal with the first
                problem.
                <br></br>
                <br></br>
                How might we help people know how impactful their renovations
                efforts would be up front?
                <br></br>
                <br></br>
                We investigated approaches, and created a product that aimed to
                help people with estimating the effects of renovation changes on
                energy consumption.
              </Text>
            </ContentTextSection>
            <ContentImageFull
              image={SketchImage}
              imageAlt={SketchImageAlt}
              description="Show people how much they are likely to save."
              eager
            ></ContentImageFull>
            {/* <ContentImageFull
              image={ComponentsImage}
              imageAlt={ComponentsImageAlt}
              description="Building blocks in Figma."
            ></ContentImageFull> */}
            {/* <ContentImageFull
              image={SpecsImage}
              imageAlt={SpecsImageAlt}
            ></ContentImageFull> */}
            <ContentImageCentered
              image={PhoneImage}
              imageAlt={PhoneImageAlt}
              description={'Green energy loans'}
              eager
            ></ContentImageCentered>
          </WorkSectionContentWrapper>
        </WorkSectionWrapper>
      );
    }}
  />
);
